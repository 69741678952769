import moment from 'moment';

export const FormatTimestamp = (timestamp) => {
    return timestamp ? moment(timestamp).format('MMMM Do YYYY, HH:mm') : undefined;
}

export const compareByEpoch = ( a, b ) => {
    if ( a.createdDate < b.createdDate ){
      return 1;
    }
    if ( a.createdDate > b.createdDate ){
      return -1;
    }
    return 0;
}

export const compareByLastModifiedEpoch = ( a, b ) => {
  if ( a.lastModifiedDate < b.lastModifiedDate ){
    return 1;
  }
  if ( a.lastModifiedDate > b.lastModifiedDate ){
    return -1;
  }
  return 0;
}